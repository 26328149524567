/* style={{ padding: 10, fontWeight: 'bolder' }} */
.floorplan_text{
    padding: 10px;
    font-weight: bolder;
}

.floorplan_img{
    width: 100%;
}

@media only screen and (max-width: 1000px){
    .floorplan_text{
       display: none;
    }
    
    .floorplan_img{
       display: none;
    }
}